define("@smith-carson/ui/utils/human-file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanFileSize;

  function humanFileSize(bytes) {
    var round = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (bytes < 1024 * 1024) {
      var kb = bytes / 1024;

      if (round) {
        kb = kb.toFixed(round);
      }

      return kb + ' kB';
    }

    var mb = bytes / 1024 / 1024;

    if (round) {
      mb = mb.toFixed(round);
    }

    return mb + ' MB';
  }
});