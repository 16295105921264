define("js2-admin/pods/components/matters-alt/tab-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QqN0HH+M",
    "block": "{\"symbols\":[\"Navbar\",\"Nav\"],\"statements\":[[5,\"app-nav-secondary\",[],[[\"@color\"],[\"smoke\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"navbar-header\"],[9],[0,\"\\n        \"],[1,[25,[\"navbar\",\"toggle\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[6,[24,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[24,1,[\"nav\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,2,[\"link-to\"]],\"expected `Nav.link-to` to be a contextual component but found a string. Did you mean `(component Nav.link-to)`? ('js2-admin/pods/components/matters-alt/tab-navigation/template.hbs' @ L9:C19) \"],null],\"matters-alt\"],null,{\"statements\":[[0,\"                    Cases (Alt)\\n\"]],\"parameters\":[]},null],[0,\"            \"]],\"parameters\":[]}],[0,\"\\n\\n            \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,2,[\"link-to\"]],\"expected `Nav.link-to` to be a contextual component but found a string. Did you mean `(component Nav.link-to)`? ('js2-admin/pods/components/matters-alt/tab-navigation/template.hbs' @ L15:C19) \"],null],\"caseload-planner\"],null,{\"statements\":[[0,\"                    Caseload Planner\\n\"]],\"parameters\":[]},null],[0,\"            \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matters-alt/tab-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});