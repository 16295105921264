define("js-common/mixins/group-document-by-report-and-doc-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reportTabDocuments: Ember.computed('documents.[]', 'fileTypes.[]', 'documents.@each.tabNumber', function () {
      var documents = this.documents.filter(function (document) {
        return document.get('tabNumber') || document.get('docType') === 'REPORT TAB' || document.get('report') && document.get('docType') !== 'REPORT';
      });
      var sortedTypesNames = this.fileTypes.mapBy('value'); // documents with tabNumber are sorted by tabNumber, else alphabetically

      documents.sort(function (a, b) {
        var aTabNumber = a.get('tabNumber');
        var bTabNumber = b.get('tabNumber');
        var aDocType = a.get('docType');
        var bDocType = b.get('docType');
        var aDocTypeIndex = sortedTypesNames.indexOf(aDocType);
        var bDocTypeIndex = sortedTypesNames.indexOf(bDocType);

        if (!Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber)) {
          return aTabNumber < bTabNumber ? -1 : aTabNumber > bTabNumber ? 1 : a.get('name') < b.get('name') ? -1 : 1;
        }

        if (!Ember.isEmpty(aTabNumber) && Ember.isEmpty(bTabNumber)) {
          return -1;
        }

        if (Ember.isEmpty(aTabNumber) && !Ember.isEmpty(bTabNumber)) {
          return 1;
        }

        if (Ember.isEmpty(aTabNumber) && Ember.isEmpty(bTabNumber)) {
          if (!Ember.isEmpty(aDocType) && Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex < bDocTypeIndex) {
            // Doc A is lower in order than Doc B based on doc type's order
            return -1;
          }

          if (Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) || !Ember.isEmpty(aDocType) && !Ember.isEmpty(bDocType) && aDocTypeIndex > bDocTypeIndex) {
            // Doc A is higher in order than Doc B based on doc type
            return 1;
          } // Doc A is equal in order to Doc B based on doc type - check against name now


          return a.get('data.name') < b.get('data.name') ? -1 : 1;
        }
      });
      return documents;
    }),
    groupedReportTabDocuments: Ember.computed('reportTabDocuments', function () {
      var groupedByReports = this.reportTabDocuments.sortBy('nameOfReport').reduce(function (group, doc) {
        // Replace . with Unicode Character 'ONE DOT LEADER' (U+2024) because a bug in each-in that doesn't allow keys with dots
        var reportName = doc.get('nameOfReport').replaceAll('.', "\u2024");
        group[reportName] = group[reportName] ? group[reportName] : [];
        group[reportName].push(doc);
        return group;
      }, {});

      if (Object.keys(groupedByReports).length > 1) {
        return groupedByReports;
      }

      return {
        '': this.reportTabDocuments
      };
    }),
    reportDocuments: Ember.computed('documents.[]', 'reportTabDocuments', function () {
      var _this = this;

      return this.documents.filter(function (document) {
        return !_this.reportTabDocuments.includes(document);
      }).filter(function (document) {
        return document.get('docType') === 'REPORT';
      });
    }),
    nonReportTabDocuments: Ember.computed('documents.[]', 'reportTabDocuments', 'reportDocuments', function () {
      var _this2 = this;

      return this.documents.filter(function (document) {
        return !_this2.reportTabDocuments.includes(document) && !_this2.reportDocuments.includes(document);
      });
    }),
    groupedDocuments: Ember.computed('documents.[]', 'fileTypes.[]', 'nonReportTabDocuments.[]', function () {
      var sortedTypes = this.fileTypes; // already sorted in route

      var sortedTypesNames = this.fileTypes.mapBy('value');
      var grouped = {};
      var nonReportTabDocuments = this.nonReportTabDocuments;
      var noTypeDocs = this.documents.filterBy('docType', null);
      grouped['no-type'] = noTypeDocs;
      sortedTypes.forEach(function (type) {
        var typeName = type.value;
        var docs = nonReportTabDocuments.filterBy('docType', typeName).sortBy('name');

        if (docs.length) {
          grouped[typeName] = docs;
        }
      }); // documents with docTypes that are not found in the fileTypes go under 'unclassified'

      var unclassifiedDocuments = nonReportTabDocuments.filter(function (document) {
        return document.docType === 'undefined' || sortedTypesNames.indexOf(document.docType) < 0;
      });

      if (unclassifiedDocuments.length) {
        if (grouped.UNCLASSIFIED) {
          grouped.UNCLASSIFIED.push(unclassifiedDocuments);
        } else {
          grouped.UNCLASSIFIED = unclassifiedDocuments;
        }
      }

      return grouped;
    })
  });

  _exports.default = _default;
});