define("js2-admin/pods/caseload-planner/controller", ["exports", "js-common/mixins/crud/error", "moment"], function (_exports, _error, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_error.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    queryParams: ['startWeek', 'limitWeeks'],
    startWeek: null,
    limitWeeks: 6,
    isAnyPlanDirty: Ember.computed('plans.@each.hasDirtyAttributes', function () {
      return !this.plans.any(function (plan) {
        return plan.get('hasDirtyAttributes');
      });
    }),
    actions: {
      callProrate: function callProrate() {
        var _this = this;

        var day = (0, _moment.default)(this.prorateStartDate, 'YYYY-MM-DD');

        if (!day.isValid()) {
          this.notify.error('We need a valid date');
          return;
        }

        if (day.format('d') !== '1') {
          this.notify.error('Must be a Monday');
          return;
        }

        this.store.createRecord('caseload-week').prorate({
          from_date: this.prorateStartDate
        }).then(function () {
          _this.notify.success('Case load has been generated successfully');

          _this.send('refreshModel');

          _this.set('selectGenerateDate', false);
        }).catch(function () {
          _this.notify.error('Failed to generate case load');
        });
      },
      save: function save() {
        var _this2 = this;

        var savePromises = this.store.peekAll('caseload-plan').filterBy('hasDirtyAttributes').map(function (plan) {
          return plan.save();
        });
        Ember.RSVP.all(savePromises).then(function () {
          _this2.notify.success('Case load has been saved successfully');

          _this2.send('refreshModel');
        });
      },
      assignToWeek: function assignToWeek(plan, week) {
        plan.set('caseloadWeek', week);
        plan.set('yearWeek', week.get('yearWeek'));
      },
      splitCase: function splitCase() {
        var _this3 = this;

        var casePlan = this.splitCasePlan;
        var splitParts = parseInt(this.splitParts, 10);

        if (splitParts < 0 || splitParts > 10) {
          this.notify.error('Invalid number of parts');
          return;
        }

        var remainign = casePlan.editableRemaining;
        var split = Number(remainign / splitParts).toFixed(2);
        var promises = [];
        casePlan.set('editableRemaining', split);
        promises.pushObject(casePlan.save());

        for (var i = 0; i < splitParts - 1; i += 1) {
          var newPlan = this.store.createRecord('caseload-plan', {
            collection: casePlan.collection,
            matter: casePlan.matter,
            matterName: casePlan.matterName,
            editableRemaining: casePlan.editableRemaining,
            notes: 'Split',
            originalRemaining: casePlan.originalRemaining,
            yearWeek: casePlan.yearWeek
          });
          promises.pushObject(newPlan.save());
        }

        Ember.RSVP.all(promises).then(function () {
          _this3.notify.success('Case has been split successfully');

          _this3.set('splitCasePlan', null);

          _this3.send('refreshModel');
        });
      },
      createPlaceholder: function createPlaceholder() {
        var _this4 = this;

        if (Ember.isEmpty(this.placeholderCollection)) {
          this.notify.error('Please select a collection');
          return;
        }

        if (Ember.isEmpty(this.placeholderName)) {
          this.notify.error('Please enter a name');
          return;
        }

        var plan = this.store.createRecord('caseload-plan', {
          matterName: this.placeholderName,
          collection: this.placeholderCollection,
          collectionId: this.placeholderCollection.id,
          editableRemaining: this.placeholderRemaining,
          originalRemaining: 0,
          notes: this.placeholderNotes,
          yearWeek: this.newPlaceholderForWeek.get('yearWeek')
        });
        plan.save().then(function () {
          _this4.notify.success('Placeholder has been created successfully');

          _this4.set('placeholderName', '');

          _this4.set('placeholderRemaining', 0);

          _this4.set('placeholderNotes', '');

          _this4.set('placeholderCollection', null);

          _this4.set('newPlaceholderForWeek', null);

          _this4.send('refreshModel');
        }).catch(function () {
          _this4.validationReport(plan);
        });
      }
    }
  });

  _exports.default = _default;
});