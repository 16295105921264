define("js-common/mixins/document-model", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-data", "js-common/utils/doc-type-display-name", "ember-api-actions"], function (_exports, _toConsumableArray2, _emberData, _docTypeDisplayName, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany; // eslint-disable-next-line ember/no-new-mixins

  var _default = Ember.Mixin.create({
    name: attr('string'),
    size: attr('number'),
    reportName: attr('string'),
    tabNumber: attr('number'),
    indexedOn: attr('estdate'),
    tmNum: attr('number'),
    description: attr('string'),
    type: attr('string'),
    docName: attr('string'),
    docType: attr('string'),
    docTypeOrder: attr('number'),
    // only used for sorting not backed on the API
    socnetName: attr('string'),
    ocrStatus: attr('number'),
    notes: attr('string'),
    parentId: attr('string'),
    // resource fields
    childTable: attr('string'),
    syncId: attr('number'),
    createdOn: attr('estdate'),
    updatedOn: attr('estdate'),
    viewableOn: attr('estdate'),
    viewable: attr('number'),
    doNotUse: attr('number'),
    // has the logged in user viewed this resource recently
    hasBeenViewed: attr('number'),
    // UI properties
    isEditing: attr('boolean', {
      defaultValue: false
    }),
    firstName: attr('string'),
    lastName: attr('string'),
    groupBy: attr('string'),
    ocrIndexStatus: attr('string', {
      defaultValue: 'not-processed'
    }),
    ocrIndexPostedOn: attr('estdate'),
    // relationship
    matter: belongsTo('matter', {
      async: true
    }),
    resource: _emberData.default.belongsTo('resource', {
      async: false,
      inverse: null
    }),
    resourceHasTags: Ember.computed('resource.resourceHasTags.[]', function () {
      if (this.belongsTo('resource').value()) {
        return this.resource.get('resourceHasTags');
      }

      return [];
    }),
    tags: Ember.computed('resourceHasTags.@each.tag', function () {
      if (this.resourceHasTags && this.resourceHasTags.length) {
        var tags = this.resourceHasTags.toArray().map(function (rht) {
          return rht.tag;
        });
        return (0, _toConsumableArray2.default)(new Set(tags));
      }

      return [];
    }),
    report: belongsTo('report', {
      async: false
    }),
    post: belongsTo('post', {
      async: false
    }),
    scrape: belongsTo('scrape', {
      async: false
    }),
    subject: belongsTo('subject', {
      async: false
    }),
    additionalSubjects: hasMany('subject', {
      async: false,
      serialize: true
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    timelineEvents: hasMany('timeline-event', {
      async: false
    }),
    afterSubject: false,
    // make sure related subjects, resource_has_tags, tags are loaded into store when using this
    fullName: Ember.computed('afterSubject', 'docType', 'matter', 'name', 'resourceHasTags.[]', 'subject', 'tabNumber', function () {
      var fullNameParts = [];
      var subject = this.subject;
      var docType = this.docType;
      var resourceHasTags = this.resourceHasTags;
      var name = this.name;

      if (!this.afterSubject) {
        if (this.tabNumber) {
          fullNameParts.push("".concat(this.tabNumber));
        }
      }

      if (subject && this.matter.get('displaySubject') === 'Y') {
        fullNameParts.push(subject.get('fullName'));
      }

      if (this.afterSubject) {
        if (this.tabNumber) {
          fullNameParts.push(this.tabNumber);
        }
      }

      if (docType && this.matter.get('displayDocType') === 'Y') {
        fullNameParts.push(docType);
      }

      var fileSplitByStop = name.split('.');
      var fileExtension = fileSplitByStop[fileSplitByStop.length - 1];
      fileSplitByStop.pop();
      var fileName = fileSplitByStop.join('.');
      fullNameParts.push(fileName);
      var tags = '';

      if (resourceHasTags) {
        resourceHasTags.forEach(function (rht) {
          if (rht.get('tag.isInternal')) {
            return;
          }

          tags += " (".concat(rht.get('tag.name'), ")");
        });
      }

      var fullNameString = fullNameParts.join(' - ');
      fullNameString += tags;
      fullNameString += ".".concat(fileExtension);
      return fullNameString;
    }),
    fullNameAdmin: Ember.computed('afterSubject', 'docType', 'matter', 'name', 'resourceHasTags.[]', 'subject', 'tabNumber', function () {
      var fullNameParts = [];
      var subject = this.subject;
      var docType = this.docType;
      var resourceHasTags = this.resourceHasTags;
      var name = this.name;

      if (!this.afterSubject) {
        if (this.tabNumber) {
          fullNameParts.push("".concat(this.tabNumber));
        }
      }

      if (subject) {
        fullNameParts.push(subject.get('fullName'));
      }

      if (this.afterSubject) {
        if (this.tabNumber) {
          fullNameParts.push(this.tabNumber);
        }
      }

      if (docType) {
        fullNameParts.push(docType);
      }

      var fileSplitByStop = name.split('.');
      var fileExtension = fileSplitByStop[fileSplitByStop.length - 1];
      fileSplitByStop.pop();
      var fileName = fileSplitByStop.join('.');
      fullNameParts.push(fileName);
      var tags = '';

      if (resourceHasTags) {
        resourceHasTags.forEach(function (rht) {
          if (rht.get('tag.isInternal')) {
            return;
          }

          tags += " (".concat(rht.get('tag.name'), ")");
        });
      }

      var fullNameString = fullNameParts.join(' - ');
      fullNameString += tags;
      fullNameString += ".".concat(fileExtension);
      return fullNameString;
    }),
    fullNameAndTab: Ember.computed('fullName', 'afterSubject', function () {
      // eslint-disable-next-line ember/no-side-effects
      this.set('afterSubject', true);
      return this.fullName;
    }),
    nameAndTab: Ember.computed('matter', 'name', 'tabNumber', function () {
      var name = this.name;

      if (this.tabNumber) {
        name += name ? ' - ' : '';
        name += this.tabNumber;
      }

      return name;
    }),
    tabAndFullName: Ember.computed('fullName', 'afterSubject', function () {
      // eslint-disable-next-line ember/no-side-effects
      this.set('afterSubject', false);
      return this.fullName;
    }),
    tabAndFullNameAdmin: Ember.computed('fullNameAdmin', 'afterSubject', function () {
      // eslint-disable-next-line ember/no-side-effects
      this.set('afterSubject', false);
      return this.fullNameAdmin;
    }),
    hasBeenIndexed: Ember.computed('indexedOn', function () {
      var indexedOn = this.indexedOn;

      if (indexedOn) {
        return indexedOn.getFullYear() > 1901; // if the file does not exist, a decoy date is set.
      }

      return false;
    }),
    hasBeenOCR: Ember.computed.gte('ocrStatus', 3),
    isDeletable: Ember.computed('docType', {
      get: function get(type) {
        return type === '';
      }
    }),
    faIconName: Ember.computed('type', function () {
      switch (this.type) {
        case 'application/pdf':
          return 'file-pdf-o';

        case 'application/vnd.ms-office':
        case 'application/msword':
        case 'application/html':
        case 'text/html':
        case 'text/rtf':
        case 'application/csv':
          return 'file-text-o';

        case 'application/vnd.ms-excel':
          return 'file-excel-o';

        case 'application/vnd.ms-powerpoint':
          return 'file-powerpoint-o';

        case 'image/jpeg':
        case 'image/tiff':
        case 'image/png':
        case 'image/gif':
        case 'image/x-ms-bmp':
          return 'file-image-o';

        case 'video/mpeg':
          return 'file-video-o';

        default:
          return 'file-o';
      }
    }),

    /**
     * calculate a friendly file size for display
     */
    friendlySize: Ember.computed('size', function () {
      var bytes = this.size;
      var decimals = 0;

      if (bytes === 0) {
        return '0';
      }

      var k = 1024; // Or 1 kilo = 1000

      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));

      if (i === 0) {
        i = 1;
        bytes /= 1024;
        return "".concat(parseFloat(bytes).toFixed(2), " ").concat(sizes[i]);
      }

      return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)), " ").concat(sizes[i]);
    }),
    groupField: Ember.computed('docType', 'groupBy', 'report.name', 'subject.{fullName,lastName}', function () {
      var groupBy = this.groupBy;

      if (groupBy === 'reportName') {
        return this.get('report.name');
      }

      if (groupBy === 'subject') {
        if (this.get('subject.lastName') === null || this.get('subject.lastName') === undefined) {
          return null;
        }

        return this.get('subject.fullName');
      }

      if (groupBy === 'viewable') {
        switch (this.get(groupBy)) {
          case 0:
            return 'Private';

          case 1:
            return 'Public';

          case 2:
            return 'Pending';

          default:
        }
      } else if (groupBy) {
        return this.get(groupBy);
      }

      return '';
    }),
    nameOfReport: Ember.computed('report.name', function () {
      return this.report ? this.get('report.name') : 'No Report';
    }),
    docTypeReadName: Ember.computed('docType', function () {
      return (0, _docTypeDisplayName.default)(this.docType);
    }),
    ocrIndexStatusLabel: Ember.computed('ocrIndexStatus', function () {
      switch (this.ocrIndexStatus) {
        case 'not-processed':
          return 'Not Processed';

        case 'processing':
          return 'Processing';

        case 'done':
          return 'Done';

        case 'failed':
          return 'Failed';

        default:
          return 'Unknown';
      }
    }),
    ocrIndexStatusIcon: Ember.computed('ocrIndexStatus', function () {
      switch (this.ocrIndexStatus) {
        case 'not-processed':
          return 'file-o';

        case 'processing':
          return 'gear';

        case 'done':
          return 'file-text-o';

        case 'failed':
          return 'exclamation-circle';

        default:
          return 'file-pdf-o';
      }
    }),
    ocrAndIndex: attr('number', {
      defaultValue: 0
    }),
    searchDocuments: (0, _emberApiActions.collectionAction)({
      path: 'search',
      type: 'get'
    }),
    ocrTheFile: (0, _emberApiActions.memberAction)({
      path: 'ocr-the-file',
      type: 'post'
    }),
    askAi: (0, _emberApiActions.memberAction)({
      path: 'ai_response_from_prompt',
      type: 'get'
    })
  });

  _exports.default = _default;
});