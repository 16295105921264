define("js2-admin/pods/components/modals/document/create-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHegurI4",
    "block": "{\"symbols\":[\"Modal\",\"Form\",\"@formOptions\",\"@open\",\"@onHidden\",\"@size\",\"@title\",\"@origin\",\"@model\",\"@multipleForms\",\"@models\",\"@modelDefaults\",\"@onSave\",\"@onCancel\"],\"statements\":[[5,\"sc-modal\",[],[[\"@open\",\"@onHidden\",\"@size\"],[[24,4,[]],[24,5,[]],[29,\"or\",[[24,6,[]],\"xl\"],null]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"span\"],[9],[3,\"did-insert\",[[29,\"auto-refresh-pause\",null,null]]],[3,\"will-destroy\",[[29,\"auto-refresh-resume\",null,null]]],[10],[0,\"\\n\\n  \"],[6,[24,1,[\"header\"]],[],[[\"@title\"],[[24,7,[]]]]],[0,\"\\n\\n  \"],[6,[24,0,[\"formComponent\"]],[],[[\"@origin\",\"@model\",\"@models\",\"@modelDefaults\",\"@validExtensions\",\"@onSave\",\"@onCancel\"],[[24,8,[]],[29,\"if\",[[29,\"not\",[[24,10,[]]],null],[24,9,[]]],null],[29,\"if\",[[24,10,[]],[24,11,[]]],null],[24,12,[]],[24,0,[\"validExtensions\"]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,13,[]]],null]],null],[29,\"action\",[[24,0,[]],[24,1,[\"close\"]]],null]],null],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"optional\",[[24,14,[]]],null]],null],[29,\"action\",[[24,0,[]],[24,1,[\"close\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,10,[]]],null,{\"statements\":[[0,\"        \"],[6,[24,2,[\"forms\"]],[],[[\"@options\"],[[24,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[24,2,[\"form\"]],[],[[\"@options\"],[[24,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[24,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[24,2,[\"controls\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/modals/document/create-update/template.hbs"
    }
  });

  _exports.default = _default;
});