define("js2-admin/pods/components/modals/trial-data-filters/component", ["exports", "js2-admin/pods/components/modals/trial-data-filters/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Provides affordance for filtering cases in a modal sidebar.
  
      @module Modals::TrialDataFiltersComponent
      @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['side-panel', 'side-panel-left'],
    layout: _template.default
  });

  _exports.default = _default;
});