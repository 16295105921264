define("@smith-carson/ui/templates/components/sc-light-table/cells/client-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uADOUCC+",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,0,[\"tagsMarkup\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,0,[\"hasTags\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-tooltip\",[[13,\"renderInPlace\",\"true\"]],[[\"@placement\"],[\"right\"]],{\"statements\":[[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"text-left\"],[9],[0,\"\\n            \"],[1,[24,0,[\"tagsMarkup\"]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/client-tags.hbs"
    }
  });

  _exports.default = _default;
});