define("@smith-carson/ui/templates/components/sc-light-table/cells/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPqK9iMu",
    "block": "{\"symbols\":[\"PopOver\",\"result\",\"@row\"],\"statements\":[[7,\"a\"],[11,\"role\",\"button\"],[11,\"tabindex\",\"0\"],[11,\"class\",\"text-jade\"],[9],[0,\"\\n    \"],[1,[29,\"capitalize\",[[24,3,[\"location\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"currentMatter\",\"allowEditLocation\"]]],null,{\"statements\":[[0,\"        \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\"],[\"pencil\",\"fa\"]]],[0,\"\\n        \"],[5,\"bs-popover\",[],[[\"@placement\",\"@triggerEvents\",\"@title\"],[\"bottom\",\"focus click\",\"Change Location\"]],{\"statements\":[[0,\"\\n            \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@renderInPlace\"],[[25,[\"currentMatter\",\"locationField\",\"options\"]],[29,\"get-appl-list\",[[25,[\"currentMatter\",\"locationField\",\"options\"]],[24,3,[\"location\"]]],null],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"mut-appl-list\",[[24,3,[]],\"location\"],null]],null],[29,\"action\",[[24,0,[]],\"saveClientData\"],null],[29,\"action\",[[24,0,[]],[24,1,[\"close\"]]],null]],null],true]],{\"statements\":[[0,\"\\n                \"],[1,[24,2,[\"display\"]],false],[0,\"\\n            \"]],\"parameters\":[2]}],[0,\"\\n            \"],[5,\"sc-button\",[[13,\"class\",\"mt-2 pull-right\"]],[[\"@color\",\"@onClick\"],[\"secondary\",[29,\"action\",[[24,0,[]],[24,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n                Cancel\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/location.hbs"
    }
  });

  _exports.default = _default;
});