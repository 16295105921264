define("@smith-carson/ui/templates/components/sc-light-table/cells/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "va6HSm6S",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[5,\"sc-icon\",[],[[\"@iconSet\",\"@icon\",\"@color\"],[\"fa\",\"file-text-o\",\"secondary\"]]],[0,\"\\n        \"],[5,\"sc-popover\",[],[[\"@title\",\"@triggerEvents\",\"@renderInPlace\",\"@placement\"],[\"Client notes:\",\"hover\",false,\"bottom\"]],{\"statements\":[[0,\"\\n            \"],[5,\"tui-editor\",[],[[\"@value\",\"@viewer\",\"@height\"],[[24,1,[]],true,\"auto\"]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/notes.hbs"
    }
  });

  _exports.default = _default;
});