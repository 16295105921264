define("js2-admin/pods/matters-alt/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    lists: Ember.inject.service(),
    queryParams: {
      as: {
        refreshModel: true
      },
      cdf: {
        refreshModel: true
      },
      cdt: {
        refreshModel: true
      },
      cg: {
        refreshModel: true
      },
      cl: {
        refreshModel: true
      },
      cmf: {
        refreshModel: true
      },
      cmt: {
        refreshModel: true
      },
      ct: {
        refreshModel: true
      },
      svt: {
        refreshModel: true
      },
      ddf: {
        refreshModel: true
      },
      ddt: {
        refreshModel: true
      },
      hd: {
        refreshModel: true
      },
      hrf: {
        refreshModel: true
      },
      hrt: {
        refreshModel: true
      },
      whf: {
        refreshModel: true
      },
      wht: {
        refreshModel: true
      },
      ubf: {
        refreshModel: true
      },
      opd: {
        refreshModel: true
      },
      ow: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      qf: {
        refreshModel: true
      },
      sortField: {
        refreshModel: true
      },
      st: {
        refreshModel: true
      },
      cst: {
        refreshModel: true
      },
      tg: {
        refreshModel: true
      },
      tgo: {
        refreshModel: true
      },
      df: {
        refreshModel: true
      }
    },
    // eslint-disable-next-line complexity
    model: function model(params) {
      var casesQuery = {};

      if (params.as) {
        casesQuery.assignees = params.as.split(',');
      }

      if (params.cl) {
        casesQuery.accounts = params.cl.split(',');
      }

      if (params.cg) {
        casesQuery.case_groups = params.cg.split(',');
      }

      if (params.cdf) {
        casesQuery.created_from = params.cdf;
      }

      if (params.cdt) {
        casesQuery.created_to = params.cdt;
      }

      if (params.cmf) {
        casesQuery.completed_from = params.cmf;
      }

      if (params.cmt) {
        casesQuery.completed_to = params.cmt;
      }

      if (params.ct) {
        casesQuery.case_types = params.ct.split(',');
      }

      if (params.svt) {
        casesQuery.types_of_service = params.svt.split(',');
      }

      if (params.ddf) {
        casesQuery.due_from = params.ddf;
      }

      if (params.ddt) {
        casesQuery.due_to = params.ddt;
      }

      if (params.hd) {
        casesQuery.show_hard_deadlines = params.hd;
      }

      if (params.hrf) {
        casesQuery.hours_remaining_from = params.hrf;
      }

      if (params.hrt) {
        casesQuery.hours_remaining_to = params.hrt;
      }

      if (params.whf) {
        casesQuery.hours_worked_from = params.whf;
      }

      if (params.wht) {
        casesQuery.hours_worked_to = params.wht;
      }

      if (params.ubf) {
        casesQuery.used_budget_from = params.ubf;
      }

      if (params.jd) {
        casesQuery.venues = params.jd.split(',');
      }

      if (params.opd) {
        casesQuery.only_past_due = params.opd;
      }

      if (params.ow) {
        casesQuery.owner_ids = params.ow.split(',');
      }

      if (params.page) {
        casesQuery.page = params.page;
      }

      if (params.perPage) {
        casesQuery.perPage = params.perPage;
      }

      if (params.q) {
        casesQuery.query_value = params.q;
      }

      if (params.qf) {
        casesQuery.query_field = params.qf.toLowerCase();
      }

      if (params.sortField) {
        casesQuery.sortField = params.sortField;
      }

      if (params.st) {
        casesQuery.case_status = params.st.split(',');
      }

      if (params.cst) {
        casesQuery.status = params.cst.split(',');
      }

      if (params.tg) {
        casesQuery.tags = params.tg.split(',');
        casesQuery.tags_operator = params.tgo.toLowerCase();
      }

      if (params.wnc) {
        casesQuery.workflow_not_completed = params.wnc;
      }

      if (params.df) {
        casesQuery.dynamic_filters = params.df;
      }

      return Ember.RSVP.hash({
        assignees: this.store.query('employee', {
          status: 'Active',
          role: ['Executive', 'Case Manager', 'Case Researcher', 'Case Support', 'Report Writer'],
          sortField: 'last_name,first_name',
          include_in_scheduling: 1
        }),
        caseGroups: this.store.query('collection', {
          sortField: 'name',
          with: 'collection_columns'
        }),
        serviceTypes: this.lists.getListForGroup('service_types'),
        cases: this.store.query('case', casesQuery),
        caseTypes: this.store.findAll('matter-type'),
        owners: this.store.query('employee', {
          status: 'Active',
          role: ['Executive', 'Case Manager'],
          sortField: 'last_name,first_name'
        }),
        publicTags: this.store.findAll('matter-tag', {
          sortField: 'name',
          limit: 999999
        }),
        privateTags: this.store.findAll('matter-private-tag', {
          sortField: 'name',
          limit: 999999
        }),
        params: params,
        casesQuery: casesQuery
      });
    },
    setupController: function setupController(controller, model) {
      var assignees = model.assignees,
          caseGroups = model.caseGroups,
          cases = model.cases,
          caseTypes = model.caseTypes,
          serviceTypes = model.serviceTypes,
          owners = model.owners,
          params = model.params,
          privateTags = model.privateTags,
          publicTags = model.publicTags,
          casesQuery = model.casesQuery;
      var totalRecords = cases.meta.total_records;
      controller.setProperties({
        assignees: assignees.sortBy('firstName'),
        caseGroups: caseGroups,
        cases: cases,
        caseSearchCategory: Ember.isPresent(params.qf) ? params.qf : 'All',
        caseTypes: caseTypes,
        serviceTypes: serviceTypes,
        owners: owners.sortBy('firstName'),
        privateTags: privateTags,
        publicTags: publicTags,
        searchTerms: params.q,
        tags: privateTags.toArray().concat(publicTags.toArray()),
        totalRecords: totalRecords,
        casesQuery: casesQuery
      }); // Do not render the table until the data is loaded

      Ember.run.schedule('afterRender', function () {
        controller.set('isLoading', false);
      });
    },
    actions: {
      /**
          Updates the URL to include the relevant query parameter values when
          they are set programmatically.
           @method didTransition
          @returns {Boolean}
       */
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          window.history.replaceState({}, '', // eslint-disable-next-line ember/no-controller-access-in-routes
          "/matters-alt?".concat(_this.controller.buildQueryParamsString()));
        });
        return true;
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});