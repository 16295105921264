define("js-common/utils/filter-matter-fields-by-subject-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterMatterFieldsBySubjectType;

  /**
   * @function
   *
   * params: matterFields (array), subjectType (string), filterOutPublishedOn (bool)
   * returns: a list of matter fields from which certain items are removed, depending on the subjectType
   */
  function filterMatterFieldsBySubjectType(matterFields, subjectType) {
    var filterOutPublishedOn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var relatedTableFields = [{
      field: 'cases',
      table: 'juror_subjects'
    }, {
      field: 'locationOrder',
      table: 'juror_subjects'
    }, {
      field: 'foreperson',
      table: 'juror_subjects'
    }, {
      field: 'courtPanel',
      table: 'juror_subjects'
    }, {
      field: 'courtJuror',
      table: 'juror_subjects'
    }, {
      field: 'injured',
      table: 'plaintiff_subjects'
    }, {
      field: 'broughtSuit',
      table: 'plaintiff_subjects'
    }];
    var fieldsToRemove = [];

    switch (subjectType) {
      case 'juror_subjects':
        fieldsToRemove = relatedTableFields.rejectBy('table', 'juror_subjects');
        break;

      case 'plaintiff_subjects':
        fieldsToRemove = relatedTableFields.rejectBy('table', 'plaintiff_subjects');
        break;

      case 'witness_subjects':
        fieldsToRemove = relatedTableFields.rejectBy('table', 'witness_subjects');
        break;

      case 'other_subjects':
        fieldsToRemove = relatedTableFields.rejectBy('table', 'other_subjects');
        break;

      case 'decedent_subjects':
        fieldsToRemove = relatedTableFields.rejectBy('table', 'decedent_subjects');
        break;

      default:
        fieldsToRemove = relatedTableFields;
    }

    if (filterOutPublishedOn) {
      fieldsToRemove.push({
        field: 'publishedOn',
        table: ''
      }, {
        field: 'publishedBy',
        table: ''
      });
    }

    return matterFields.reject(function (field) {
      return fieldsToRemove.findBy('field', Ember.String.camelize(field.get('value'))) !== undefined;
    });
  }
});