define("js-common/models/caseload-week", ["exports", "ember-data", "ember-api-actions", "moment"], function (_exports, _emberData, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    yearWeek: attr('string'),
    weekStart: attr('estdate'),
    avgHours: attr('number'),
    notes: attr('string'),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    updatedOn: attr('estdate'),
    createdOn: attr('estdate'),
    caseloadPlans: hasMany('caseload-plan', {
      async: true
    }),
    prorate: (0, _emberApiActions.collectionAction)({
      path: 'prorate',
      type: 'post'
    }),
    difference: Ember.computed('caseloadPlans.@each.editableRemaining', function () {
      var avgHours = this.avgHours,
          caseloadPlans = this.caseloadPlans;
      var totalRemaining = caseloadPlans.reduce(function (acc, plan) {
        return acc + parseFloat(plan.editableRemaining);
      }, 0);
      return avgHours - totalRemaining;
    }),
    colorOfDifference: Ember.computed('difference', function () {
      if (this.difference > 0) {
        return 'bg-success';
      }

      return 'bg-danger';
    })
  });

  _exports.default = _default;
});