define("js2-admin/initializers/before-unload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* eslint no-undef: 1 */
  function initialize()
  /* application */
  {
    window.addEventListener('beforeunload', function (event) {
      // show the confirm only if modal dialog exists or there are a button on the dom for Save
      if (!!document.querySelector('.modal-dialog') || $('button.btn-primary:contains(\'Save\'):enabled').length - $('button.btn-primary:contains(\'Save Document\')').length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});