define("js-common/models/caseload-plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    yearWeek: attr('string'),
    collectionId: attr('number'),
    matterId: attr('number'),
    matterName: attr('string'),
    originalRemaining: attr('number'),
    editableRemaining: attr('number'),
    notes: attr('string'),
    createdBy: belongsTo('user', {
      async: true
    }),
    updatedBy: belongsTo('user', {
      async: true
    }),
    updatedOn: attr('estdate'),
    createdOn: attr('estdate'),
    caseloadWeek: belongsTo('caseload-week'),
    matter: belongsTo('matter'),
    collection: belongsTo('collection')
  });

  _exports.default = _default;
});