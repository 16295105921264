define("@smith-carson/ui/templates/components/sc-light-table/cells/ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KoQLltue",
    "block": "{\"symbols\":[\"@value\",\"@row\"],\"statements\":[[5,\"sc-ranking\",[],[[\"@currentValue\",\"@onChange\"],[[24,1,[]],[29,\"pipe\",[[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,2,[\"clientRanking\"]]],null]],null],[29,\"action\",[[24,0,[]],\"saveClientData\"],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-light-table/cells/ranking.hbs"
    }
  });

  _exports.default = _default;
});