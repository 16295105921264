define("js-common/templates/components/subject-state-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rVl+5I2w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-badge\",[],[[\"@color\",\"@size\",\"@label\",\"@shape\"],[[24,0,[\"badgeColor\"]],[24,0,[\"size\"]],[24,0,[\"label\"]],\"pill\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-state-badge.hbs"
    }
  });

  _exports.default = _default;
});