define("js-admin-common/templates/components/input-resource-viewable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KK2aaY82",
    "block": "{\"symbols\":[\"result\",\"@doNotUse\",\"@useTmOptions\",\"@placeholder\",\"@classNames\"],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"onChangeDoNotUse\"]]],null,{\"statements\":[[0,\"        \"],[5,\"sc-checkbox\",[],[[\"@checked\",\"@onChange\"],[[24,2,[]],[29,\"action\",[[24,0,[]],\"changeDoNotUse\"],null]]],{\"statements\":[[0,\"\\n            Do not use\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"flex-grow-1 \",[29,\"if\",[[24,2,[]],\"invisible\"],null]]]],[9],[0,\"\\n        \"],[5,\"sc-power-select\",[],[[\"@renderInPlace\",\"@options\",\"@selected\",\"@onchange\",\"@allowClear\",\"@placeholder\",\"@classNames\"],[true,[29,\"if\",[[24,3,[]],[24,0,[\"tmViewableOptions\"]],[24,0,[\"viewableOptions\"]]],null],[23,\"selectedViewable\"],[29,\"action\",[[24,0,[]],\"changeViewable\"],null],false,[24,4,[]],[24,5,[]]]],{\"statements\":[[0,\"\\n            \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\",\"@size\"],[[24,1,[\"icon\"]],[24,1,[\"color\"]],\"sm\"]]],[0,\"\\n            \"],[1,[24,1,[\"display\"]],false],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-admin-common/templates/components/input-resource-viewable.hbs"
    }
  });

  _exports.default = _default;
});