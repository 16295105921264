define("js2-admin/components/dynamic-filter/select-operator", ["exports", "@smith-carson/ui/components/dynamic-filter/select-operator"], function (_exports, _selectOperator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _selectOperator.default;
    }
  });
});