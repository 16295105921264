define("js-common/templates/components/subjects/client-show-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCdx3c+A",
    "block": "{\"symbols\":[\"tag\",\"tag\"],\"statements\":[[4,\"each\",[[25,[\"visibleTags\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sc-badge\",[[13,\"class\",\"badge-client-tag\"],[13,\"title\",[24,2,[\"name\"]]]],[[\"@color\"],[[29,\"if\",[[24,2,[\"public\"]],\"success\",\"info\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[24,2,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null],[4,\"if\",[[25,[\"hiddenTags\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n        +\"],[1,[25,[\"hiddenTags\",\"length\"]],false],[0,\"\\n        \"],[5,\"sc-tooltip\",[],[[\"@placement\"],[\"bottom\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"hiddenTags\"]]],null,{\"statements\":[[0,\"                \"],[5,\"sc-badge\",[[13,\"title\",[24,1,[\"name\"]]],[13,\"class\",\"badge-client-tag expanded\"]],[[\"@color\"],[[29,\"if\",[[24,1,[\"public\"]],\"success\",\"info\"],null]]],{\"statements\":[[0,\"\\n                    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n                \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subjects/client-show-tags.hbs"
    }
  });

  _exports.default = _default;
});